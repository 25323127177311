import { useTranslations } from "@/i18n/utils";
import { useEffect, useRef } from "react";

type CookiesAlertProps = {
  show: boolean;
  lang: "ar" | "en";
};

const CookieAlert: React.FC<CookiesAlertProps> = ({ show, lang }) => {
  const t = useTranslations(lang);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current && show) {
      dialogRef.current.showModal();
    }

    return () => {
      dialogRef.current?.close();
    };
  }, []);

  const handleAcceptCookies = () => {
    dialogRef.current?.close();
    document.cookie = "cookies_accepted=true; path=/; max-age=31536000";
  };

  const handleNotAcceptCookies = () => {
    history.back();
  };

  return (
    <dialog ref={dialogRef} className="modal modal-bottom sm:modal-middle">
      <div className="modal-box">
        <h3 className="font-bold text-lg">
          {t("dialog.cookies_accept.title")}
        </h3>
        <p className="py-4">{t("dialog.cookies_accept.content")}</p>
        <div className="modal-action">
          <div className="btn" onClick={handleNotAcceptCookies}>
            {t("dialog.cookies_accept.not_accept")}
          </div>
          <div className="btn btn-primary" onClick={handleAcceptCookies}>
            {t("dialog.cookies_accept.accept")}
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CookieAlert;
